<template>
  <v-row
    class="pb-8"
  >
    <v-col
      cols="12"
      sm="12"
      class="pt-0"
    >
      <carousel />
      <v-row>
        <v-col
          xs="6"
          sm="8"
          md="9"
          align-self="center"
        >
          <session-title
            class="pt-8 px-8 pb-0"
            title="Informe de Rendimento"
          />
        </v-col>
        <v-col
          class="pt-0 px-12 pb-2"
          xs="6"
          sm="4"
          md="3"
          align-self="center"
        >
          <chat-ti-modal-access-vue />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      class="pt-0 px-12"
    />
    <v-col
      cols="12"
      sm="12"
      class="pt-0 px-12"
    >
      <div
        class="text-center"
      >
        <v-select
          v-model="selected"
          :items="items"
          label="Selecione o ano"
          item-value="value"
          item-text="label"
          outlined
          color="blue darken-4"
        />
        <v-btn
          color="secondary"
          elevation="2"
          large
          @click="generateIncomeReport"
          class="mt-3"
        >
          <v-icon class="mr-3">
            mdi-file-move-outline
          </v-icon>Gerar Informe de Rendimento
        </v-btn>
        <v-alert
          v-if="loading"
          outlined
          type="warning"
          class="mt-5 text-center text-justify"
          prominent
        >
          Esse processo pode levar alguns minutos. Por favor, aguarde.
        </v-alert>

        <v-alert
          outlined
          type="info"
          class="mt-5 text-center text-justify"
          prominent
        >
          Para gerar o Informe de Rendimento, seu navegador precisa aceitar janelas popup.
          Para desbloquear acesse as Configurações > Privacidade e Segurança > Permissões e desmarque a caixa "Bloquear janelas popup".
        </v-alert>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import SessionTitle from '@/components/SessionTitle'
import { mapState } from 'vuex'
import { getIncomeReportByYear } from '../../services/income-report-service'
import Carousel from '@/components/Carousel'
import ChatTiModalAccessVue from "../../components/ModalViews/ChatTiModalAccess.vue";

export default {
  name: 'IncomeReport',
  components: {
    carousel: Carousel,
    'session-title': SessionTitle,
    ChatTiModalAccessVue
  },
  data () {
    return {
      items: [],
      selected: null
    }
  },
  computed: {
    ...mapState('loader', ['loading'])
  },
  created() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    if (currentMonth > 0) {
      this.items.push({
        label: `${currentYear - 1}`,
        value: currentYear - 1,
      });
    } else {
      this.items.push({
        label: `${currentYear - 2}`,
        value: currentYear - 2,
      });
    }
  },
  methods: {
    async generateIncomeReport () {
      if (!this.isFieldValid()) return
      try {
        const data = await getIncomeReportByYear(this.selected)
        const file = new Blob([data], { type: 'application/pdf' })
        window.open(URL.createObjectURL(file))
      } catch (error) {
        this.$handleHttpError(error)
      }
    },
    isFieldValid () {
      if (!this.selected) {
        this.$toast.error('Verifique o campo e tente novamente')
      }
      return !!this.selected
    }

  }
}
</script>
